.app-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #3d331c;
}

.body-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background: white;
}

.row-operation-container {
  display: flex;
  align-items: center;
}

#modal-content {
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.4s;
}

.modal-content-image {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
}

.ant-table-thead>tr>th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px !important;
  max-width: 250px;
  background-color: #957d53 !important;
  color: #fffefe !important;
  border: none !important;
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #bf881e !important;
}

.ant-table-tbody>tr>td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px !important;
  max-width: 250px;
}

.ant-table-thead>tr:hover td, .ant-table-tbody>tr:hover td {
  background: #fff2d9 !important;
}

.ant-table-row>td>div {
  text-align: left !important;
}

.ant-table-row>td>img {
  background: #f5f5f5 !important;
  max-height: 200px;
}

.ant-spin-nested-loading>div>.ant-spin {
  position: fixed !important;
  max-height: 100vh !important;
  left: 220px !important;
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-dot {
  left: calc(50% - 127px) !important;
}

.ant-spin-dot-item {
  background-color: #957e53 !important;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}