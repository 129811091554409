@font-face {
  font-family: 'CiutadellaLight';
  src: url('./fonts/Ciutadella-Light.otf');
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'Ciutadella';
  src: url('./fonts/Ciutadella-Regular.otf');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'CiutadellaBold';
  src: url('./fonts/Ciutadella-Bold.otf');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'LitmusLight';
  src: url('./fonts/Litmus-Light.otf');
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'Litmus';
  src: url('./fonts/Litmus.otf');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'LitmusBold';
  src: url('./fonts/Litmus-Bold.otf');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'HelvethaicaLight';
  src: url('./fonts/DB Helvethaica X Li.ttf');
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'Helvethaica';
  src: url('./fonts/DB Helvethaica X Med.ttf');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}